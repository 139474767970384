import styled from "styled-components";

export const Notification = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  width: 350px;
  padding: 20px;
  position: fixed;
  top: 10px;
  left: 10px;
  background: ${({ type, theme }) =>
    type.toUpperCase() !== "ERROR"
      ? theme.common.palette.darkGray
      : theme.common.palette.red};
  border-radius: 10px;
  z-index: 2147000000 !important;
`;
