import React from "react";
import Layout from "../components/Layout";
import Button from "../components/Buttons/Button";
import { graphql } from "gatsby";
import styled from "styled-components";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Notification } from "../components/Notification/Notification";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ShieldSocietyContainer = styled.div`
  ${({ theme }) => theme.common.palette.shieldSocietyGradient}
  padding-top:20px;
  padding-bottom: 20px;
  width: 100vw;
  min-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .react-tel-input {
    height: auto;
  }
  .containerClass {
    width: 100%;
  }
  .phoneInput {
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 24px;
    line-height: 30px;
    font-family: inherit;
    height: 100%;
    float: right;
    text-align: left;
  }
  .buttonStyle {
    padding: 5px;
  }
  .recaptchaAndJoin {
    padding: 10px;
    display: flex;
  }
  form {
    width: 100%;
    li {
      color: ${({ theme }) => theme.common.palette.darkGray};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    min-height: calc(100vh - 96px);
  }
`;

const ShieldSocietyContent = styled.div`
  min-width: 300px;
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .inputItems {
    width: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
      flex-direction: column;
      width: 90%;
    }
  }
  input {
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    font-size: 24px;
    line-height: 30px;
    padding: 5px;
    font-family: inherit;
  }
  h1 {
    ${({ theme }) => ({
      ...theme.common.typography.heroLarge,
      color: theme.common.palette.red,
    })}
    line-height: 46px;
    text-transform: uppercase;
    white-space: nowrap;
    @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
      white-space: pre-wrap;
    }
  }
  label {
    margin: 0px 10px;
    ${({ theme }) => theme.common.typography.inputLabel}
    display: flex;
    flex-direction: column;
    color: white;
    flex: 1;
  }
  button {
    margin: 10px;
  }
  .subheadingAndSignature {
    position: relative;
    display: flex;
    flex-direction: row;
    @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
      flex-direction: column;
      width: calc(60vw - 60px);
      margin: 0 auto;
    }
    p {
      ${({ theme }) => theme.common.typography.bodyNormal}
      color: ${({ theme }) => theme.common.palette.white};
    }
  }
`;

const Logo = styled.div`
  width: 210px;
  height: 210px;
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    height: 160px;
    width: 160px;
  }
`;

const Hidden = styled.div`
  display: none;
`;
const SignatureContainer = styled.div`
  .signature {
    section {
      background-repeat: no-repeat;
      background-size: contain;
      width: 180px;
      height: 90px;
    }
  }
  span {
    ${({ theme }) => theme.common.typography.bodyNormal}
    font-size: 12px;
  }
  .slogan,
  .name {
    position: absolute;
    color: white;
    z-index: 2;
  }
  .slogan {
    top: 0px;
    left: 0px;
  }
  .name {
    position: absolute;
    bottom: 2px;
    right: 5px;
  }
  width: 250px;
  height: 110px;
  position: relative;
`;

export const ShieldSocietyTemplate = ({
  mainlogo,
  heading,
  subheading,
  signature,
}) => {
  const { image: signatureImage, slogan, name } = signature;
  const firstnameRef = React.useRef();
  const lastnameRef = React.useRef();
  const birthdayRef = React.useRef();
  const [smsValue, setSmsValue] = React.useState();
  const [notification, setNotification] = React.useState("");
  const [notificationVisible, setNotificationVisible] = React.useState(false);
  const [notificationType, setNotificationType] = React.useState("");
  const [notificationClearing, setNotificationClearing] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setNotification("");
      setNotificationVisible(false);
      setNotificationType("");
    }, 10000);
  }, [notification, notificationVisible, notificationType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = {
      FIRSTNAME: firstnameRef.current.value,
      SMS: smsValue,
      LASTNAME: lastnameRef.current.value,
      BIRTHDAY: new Date(birthdayRef.current.value).toISOString(),
    };

    fetch("https://janus.kentuckypar.com/.netlify/functions/joinrss", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(values), // body data type must match "Content-Type" header
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log("res", res);
        if (res.success) {
          setNotificationType("SUCCESS");
          setNotification("Successfully signed up for the Red Shield Society!");
          setNotificationVisible(true);
        }
      })
      .catch((error) => {
          setNotificationType("ERROR");
          setNotification("An error occured. Please contact us to sign up.");
          setNotificationVisible(true);
      });
  };
  return (
    <ShieldSocietyContainer>
      <Notification type={notificationType} isVisible={notificationVisible}>
        {notification}
      </Notification>
      <ShieldSocietyContent>
        <Logo>
          <PreviewCompatibleImage
            containerClass={"containerClass"}
            imageInfo={mainlogo || ""}
            Tag="section"
          />
        </Logo>
        <h1>{heading}</h1>
        <form
          name="Join_Red_Shield_Society"
          data-netlify-recaptcha="true"
          onSubmit={handleSubmit}
          data-netlify="true"
        >
          <Hidden>
            Don’t fill this out if you're human: <input name="bot-field" />
          </Hidden>
          <div className="inputItems">
            <label htmlfor="firstname">
              First name
              <input
                ref={firstnameRef}
                type="text"
                name="FIRSTNAME"
                title="firstname"
                required
              />
            </label>
            <label htmlfor="lastname">
              Last name
              <input
                ref={lastnameRef}
                type="text"
                name="LASTNAME"
                title="lastname"
                required
              />
            </label>
          </div>
          <div className="inputItems">
            <label htmlfor="birthday">
              Birthday
              <input
                ref={birthdayRef}
                type="date"
                name="BIRTHDAY"
                title="birthday"
                required
              />
            </label>
            <label htmlfor="firstname">
              Mobile #
              <PhoneInput
                inputClass="phoneInput"
                buttonClass="buttonStyle"
                country="us"
                onChange={setSmsValue}
                name="SMS"
              />
            </label>
          </div>
          <div className="inputItems recaptchaAndJoin">
            <div data-netlify-recaptcha="true"></div>
            <Button type="submit" text="Join up" />
          </div>
        </form>
        <div className="subheadingAndSignature">
          <p>{subheading}</p>
          <SignatureContainer>
            <span className="slogan">{slogan}</span>
            <div className="signature">
              <PreviewCompatibleImage
                imageInfo={signatureImage || ""}
                Tag="section"
              />
            </div>
            <span className="name">{name}</span>
          </SignatureContainer>
        </div>
      </ShieldSocietyContent>
    </ShieldSocietyContainer>
  );
};

const ShieldSociety = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;
  return (
    <Layout>
      <ShieldSocietyTemplate {...frontmatter} />
    </Layout>
  );
};

export default ShieldSociety;

export const pageQuery = graphql`
  query ShieldSocietyPage {
    markdownRemark(frontmatter: { templateKey: { eq: "redshieldsociety" } }) {
      frontmatter {
        mainlogo {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        heading
        subheading
        signature {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          slogan
          name
        }
      }
    }
  }
`;
